import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {ControlList} from "../components/items/item-list"
import Catalog from "../services/catalog"

class BrowseControlPanelsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      controls: [],
    };
  }

  componentWillMount() {
    Catalog.getControlPanels().then(controls => this.setState({controls})).catch(console.error);
  }

  render() {
    const { controls } = this.state;
    
    return(
      <Layout navTab={3}>
        <SEO title="Part Browser" />
        <ControlList items={controls}/>
      </Layout>
    );
  }
}

export default BrowseControlPanelsPage